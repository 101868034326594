import * as Configuration from './configuration'
import * as Constants from './constants'
import * as FormComponents from './form-components'
import * as Forms from '@rushplay/forms'
import * as Herz from '@rushplay/herz'
import * as Hooks from './hooks'
import * as Icons from './icons'
import * as Processes from '@rushplay/processes'
import * as R from 'ramda'
import * as React from 'react'
import * as ReactRedux from 'react-redux'
import * as ReactRouterDom from 'react-router-dom'
import * as Session from './session'
import * as Suspense from './suspense'
import * as ThemeUi from 'theme-ui'

import {QueryPopup} from './query-popup'

const dataSchema = {
  type: 'object',
  properties: {
    email: {
      type: 'string',
      pattern: Constants.EmailPattern,
    },
    password: {
      type: 'string',
      minLength: 1,
    },
  },
  required: ['email', 'password'],
}

export function Login() {
  const dispatch = ReactRedux.useDispatch()
  const history = ReactRouterDom.useHistory()
  const translate = Herz.I18n.useTranslate()
  const clientType = ReactRedux.useSelector((state) =>
    Configuration.getClientType(state.configuration)
  )
  const closeLoginQuery = Hooks.useSafeUpdateQuery({login: null})

  const loading = ReactRedux.useSelector((state) =>
    Processes.isRunning(state.processes, {
      ids: ['LOGIN_LOADING'],
    })
  )

  return (
    <Forms.Provider
      name="login"
      schema={dataSchema}
      onSubmit={(errors, userData) => {
        if (R.isEmpty(errors)) {
          dispatch([
            Session.login({...userData, clientType}, () =>
              history.push(`?${closeLoginQuery}`)
            ),
            Processes.start('LOGIN_LOADING'),
          ])
        }
      }}
    >
      <QueryPopup activeQueryName="login" title={translate('login.title')}>
        <Suspense.Boundary>
          <ThemeUi.Grid
            sx={{
              color: 'static-white',
              gridGap: 2,
              pb: 1,
              px: 1,
            }}
          >
            <FormComponents.InputField
              autoComplete="email"
              inputMode="email"
              scope="#/properties/email"
              icon={Icons.Email}
            />
            <FormComponents.PasswordInputField
              autoComplete="password"
              scope="#/properties/password"
            />
            <ThemeUi.Box
              sx={{
                justifySelf: 'end',
                my: '-8px',
              }}
            >
              <ThemeUi.Link
                as={ReactRouterDom.Link}
                to="?password-recovery=email"
                variant="footerNav"
                sx={{
                  textAlign: 'center',
                  opacity: 0.5,
                  fontWeight: 'body',
                }}
              >
                {translate('login.forgot-password')}
              </ThemeUi.Link>
            </ThemeUi.Box>
            <FormComponents.SubmitButton loading={loading} sx={{mt: 2}}>
              {translate('login.submit')}
            </FormComponents.SubmitButton>
            <ThemeUi.Box
              sx={{
                display: 'grid',
                mt: 1,
                fontSize: 1,
                gap: 1,
                textAlign: 'center',
              }}
            >
              <ThemeUi.Text>{translate('login.not-a-member')}</ThemeUi.Text>
              <ThemeUi.Link
                as={ReactRouterDom.Link}
                to="?register=now"
                sx={{
                  textAlign: 'center',
                  color: 'static-white',
                }}
              >
                {translate('login.not-a-member.link')}
              </ThemeUi.Link>
            </ThemeUi.Box>
          </ThemeUi.Grid>
        </Suspense.Boundary>
      </QueryPopup>
    </Forms.Provider>
  )
}

Herz.I18n.Loader.preload(
  [
    'login.forgot-password',
    'login.submit',
    'login.title',
    'login.not-a-member',
    'login.not-a-member.link',
  ],
  Login
)

// for @loadable/component
export default Login
