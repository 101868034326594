import * as Analytics from '@rushplay/analytics'
import * as Api from '@rushplay/api-client'
import * as Configuration from './configuration'
import * as Constants from './constants'
import * as FormComponents from './form-components'
import * as Forms from '@rushplay/forms'
import * as Herz from '@rushplay/herz'
import * as Icons from './icons'
import * as Notifications from '@rushplay/notifications'
import * as Processes from '@rushplay/processes'
import * as R from 'ramda'
import * as React from 'react'
import * as ReactRedux from 'react-redux'
import * as ReactRouterDom from 'react-router-dom'
import * as Reselect from 'reselect'
import * as Session from './session'
import * as Suspense from './suspense'
import * as ThemeUi from 'theme-ui'

import {QueryPopup} from './query-popup'

const dataSchema = {
  type: 'object',
  properties: {
    email: {
      type: 'string',
      pattern: Constants.EmailPattern,
    },
    password: {
      type: 'string',
      minLength: 8,
    },
    terms: {
      type: 'boolean',
      const: true,
    },
  },
  required: ['email', 'password', 'terms'],
}

const getRegistrationData = Reselect.createStructuredSelector({
  affiliateClickId: (state) => Analytics.getClickId(state.analytics),
  netrefererBTag: (state) => Analytics.getBtag(state.analytics),
  clientType: (state) => Configuration.getClientType(state.configuration),
  countryCode: (state) => Configuration.getCountryCode(state.configuration),
  utmCampaign: (state) => Analytics.getUtmCampaign(state.analytics),
  utmMedium: (state) => Analytics.getUtmMedium(state.analytics),
  utmSource: (state) => Analytics.getUtmSource(state.analytics),
})

export function Register() {
  const translate = Herz.I18n.useTranslate()
  const dispatch = ReactRedux.useDispatch()
  const history = ReactRouterDom.useHistory()
  const [showSuccess, setShowSuccess] = React.useState(false)
  const registrationData = ReactRedux.useSelector(getRegistrationData)

  function onSubmit(data) {
    const userData = {
      ...registrationData,
      email: data.email,
      generateUsername: true,
      language: 'en',
      password: data.password,
      passwordConfirmation: data.password,
    }

    dispatch([
      Processes.start('REGISTER_LOADING'),
      Api.registerPlayer(userData, {
        success: () => [
          Processes.stop('REGISTER_LOADING'),
          Processes.start('LOGIN_LOADING'),
          Session.login({...userData}, () => setShowSuccess(true)),
        ],
        failure: (res) => {
          if (res.status >= 500) {
            return [
              Notifications.add({
                message: 'errors.general.unknown',
                level: 'error',
              }),
              Processes.stop('REGISTER_LOADING'),
            ]
          }
          if (R.not(R.isEmpty(res.value.errors))) {
            if (R.path(['errors', 'email'], res.value)) {
              return [
                Notifications.add({
                  message: 'errors.email-already-exists',
                  level: 'error',
                }),
                Processes.stop('REGISTER_LOADING'),
              ]
            }

            return [
              Notifications.add({
                message: `errors.${res.status}`,
                level: 'error',
              }),
              Processes.stop('REGISTER_LOADING'),
            ]
          }
        },
        version: 1,
      }),
    ])
  }

  const loading = ReactRedux.useSelector((state) =>
    Processes.isRunning(state.processes, {
      ids: ['LOGIN_LOADING', 'REGISTER_LOADING'],
    })
  )

  return (
    <QueryPopup
      activeQueryName="register"
      title={
        showSuccess
          ? translate('register.success.title')
          : translate('register.title')
      }
      onClose={() => setShowSuccess(false)}
    >
      <Suspense.Boundary>
        {showSuccess ? (
          <ThemeUi.Grid
            sx={{
              color: 'static-white',
              gridGap: 2,
              px: 1,
              textAlign: 'center',
            }}
          >
            <ThemeUi.Heading
              as="h2"
              sx={{
                fontSize: 3,
              }}
            >
              {translate('register.success.sub-title')}
            </ThemeUi.Heading>
            <ThemeUi.Text
              sx={{
                fontSize: 1,
              }}
            >
              {translate('register.success.description')}
            </ThemeUi.Text>
            <ThemeUi.Button
              sx={{
                mx: 'auto',
              }}
              onClick={() => history.push('/')}
            >
              {translate('register.success.cta')}
            </ThemeUi.Button>
          </ThemeUi.Grid>
        ) : (
          <Forms.Provider
            name="register"
            schema={dataSchema}
            onSubmit={(errors, userData) => {
              if (R.isEmpty(errors)) {
                onSubmit(userData)
              }
            }}
          >
            <ThemeUi.Box
              sx={{
                display: 'grid',
                gap: 1,
                textAlign: 'center',
                pb: 2,
                fontSize: 1,
              }}
            >
              <ThemeUi.Heading
                as="h3"
                sx={{
                  fontSize: 4,
                }}
              >
                {translate('register.sub-title')}
              </ThemeUi.Heading>
              <ThemeUi.Text>{translate('register.description')}</ThemeUi.Text>
            </ThemeUi.Box>
            <ThemeUi.Grid
              sx={{
                gridGap: 2,
                pb: 1,
                px: 1,
                color: 'static-white',
              }}
            >
              <FormComponents.InputField
                autoComplete="email"
                inputMode="email"
                icon={Icons.Email}
                scope="#/properties/email"
              />
              <FormComponents.PasswordInputField
                autoComplete="password"
                scope="#/properties/password"
              />
              <ThemeUi.Box sx={{mt: 2}}>
                <FormComponents.CheckboxField scope="#/properties/terms" />
              </ThemeUi.Box>
              <FormComponents.SubmitButton
                loading={loading}
                sx={{
                  mt: 2,
                }}
              >
                {translate('register.submit')}
              </FormComponents.SubmitButton>

              <ThemeUi.Box
                sx={{
                  display: 'grid',
                  mt: 1,
                  fontSize: 1,
                  gap: 1,
                  textAlign: 'center',
                }}
              >
                <ThemeUi.Text>
                  {translate('register.already-a-member')}
                </ThemeUi.Text>
                <ThemeUi.Link
                  as={ReactRouterDom.Link}
                  to="?login=here"
                  sx={{
                    textAlign: 'center',
                    color: 'static-white',
                  }}
                >
                  {translate('register.already-a-member.link')}
                </ThemeUi.Link>
              </ThemeUi.Box>
            </ThemeUi.Grid>
          </Forms.Provider>
        )}
      </Suspense.Boundary>
    </QueryPopup>
  )
}

Herz.I18n.Loader.preload(
  [
    'register.already-a-member',
    'register.already-a-member.link',
    'register.success.cta',
    'register.success.description',
    'register.success.sub-title',
    'register.success.title',
    'register.submit',
    'register.sub-title',
    'register.description',
    'register.title',
  ],
  Register
)

// for @loadable/component
export default Register
