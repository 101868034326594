import * as Api from '@rushplay/api-client'
import * as Constants from './constants'
import * as FormComponents from './form-components'
import * as Forms from '@rushplay/forms'
import * as Herz from '@rushplay/herz'
import * as Icons from './icons'
import * as R from 'ramda'
import * as React from 'react'
import * as ReactRedux from 'react-redux'
import * as ReactRouterDom from 'react-router-dom'
import * as Suspense from './suspense'
import * as ThemeUi from 'theme-ui'

import {QueryPopup} from './query-popup'

const dataSchema = {
  type: 'object',
  properties: {
    email: {
      type: 'string',
      pattern: Constants.EmailPattern,
    },
  },
  required: ['email'],
}

export function PasswordRecovery() {
  const translate = Herz.I18n.useTranslate()
  const [showSuccess, setShowSuccess] = React.useState(false)
  const history = ReactRouterDom.useHistory()
  const dispatch = ReactRedux.useDispatch()

  return (
    <QueryPopup
      activeQueryName="password-recovery"
      backLink="?login=me"
      title={
        showSuccess
          ? translate('password-recovery.success')
          : translate('password-recovery.title')
      }
      onClose={() => setShowSuccess(false)}
    >
      <Suspense.Boundary>
        {showSuccess ? (
          <ThemeUi.Grid
            sx={{
              color: 'static-white',
              gridGap: 2,
              px: 1,
              textAlign: 'center',
            }}
          >
            <ThemeUi.Heading
              as="h2"
              sx={{
                fontSize: 3,
              }}
            >
              {translate('password-recovery.email-sent.title')}
            </ThemeUi.Heading>
            <ThemeUi.Text
              sx={{
                fontSize: 1,
              }}
            >
              {translate('password-recovery.email-sent.description')}
            </ThemeUi.Text>
            <ThemeUi.Button
              sx={{
                mx: 'auto',
              }}
              onClick={() => history.push('/')}
            >
              {translate('password-recovery.email-sent.cta')}
            </ThemeUi.Button>
          </ThemeUi.Grid>
        ) : (
          <Forms.Provider
            name="passwordRecovery"
            schema={dataSchema}
            onSubmit={(errors, userData) => {
              if (R.isEmpty(errors)) {
                dispatch(Api.resetPassword(userData.email, {version: 1}))
                setShowSuccess(true)
              }
            }}
          >
            <ThemeUi.Grid
              sx={{
                color: 'static-white',
                gridGap: 2,
                px: 1,
                textAlign: 'center',
              }}
            >
              <ThemeUi.Text
                sx={{
                  fontSize: 1,
                }}
              >
                {translate('password-recovery.description')}
              </ThemeUi.Text>
              <FormComponents.InputField
                autoComplete="email"
                inputMode="email"
                scope="#/properties/email"
                icon={Icons.Email}
              />
              <FormComponents.SubmitButton
                sx={{
                  my: 2,
                }}
              >
                {translate('password-recovery.submit')}
              </FormComponents.SubmitButton>
            </ThemeUi.Grid>
          </Forms.Provider>
        )}
      </Suspense.Boundary>
    </QueryPopup>
  )
}

Herz.I18n.Loader.preload(
  [
    'password-recovery.email-sent.title',
    'password-recovery.email-sent.description',
    'password-recovery.email-sent.cta',
    'password-recovery.success',
    'password-recovery.submit',
    'password-recovery.title',
    'password-recovery.description',
  ],
  PasswordRecovery
)

// for @loadable/component
export default PasswordRecovery
