import * as Constants from './constants'
import * as Cookies from './cookies-module'
import * as FormComponents from './form-components'
import * as Forms from '@rushplay/forms'
import * as Herz from '@rushplay/herz'
import * as Icons from './icons'
import * as React from 'react'
import * as Suspense from './suspense'
import * as ThemeUi from 'theme-ui'

import {HtmlContent} from './html-content'

const dataSchema = {
  type: 'object',
  properties: {
    baseConsent: {
      type: 'boolean',
      const: true,
    },
    adsConsent: {
      type: 'boolean',
    },
  },
  required: ['baseConsent'],
}

export function CookieConsentBanner() {
  const translate = Herz.I18n.useTranslate()
  const [cookieConsent, setCookieConsent] = Cookies.useCookie(
    Constants.CookieKeys.COOKIE_CONSENT
  )
  const [settingsVisible, setSettingsVisible] = React.useState(false)

  function handleCookieConsent() {
    setCookieConsent(true, {
      httpOnly: false,
      maxAge: 365 * 24 * 60 * 60,
      path: '/',
    })
    setSettingsVisible(false)
  }

  if (!cookieConsent) {
    return (
      <ThemeUi.Box
        sx={{
          position: 'fixed',
          bottom: '0px',
          left: '0px',
          right: '0px',
          backgroundColor: 'static-white',
          py: 1,
          px: [1, 2],
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          color: 'static-black',
          gap: 3,
        }}
      >
        <Suspense.Boundary fallback={null}>
          {settingsVisible && (
            <ThemeUi.Box
              sx={{
                position: 'absolute',
                top: '0px',
                left: '0px',
                transform: 'translateY(-100%)',
                p: 2,
                color: 'static-black',
                width: '100%',
                maxWidth: '400px',
              }}
            >
              <Forms.Provider
                name="cookie-consent"
                schema={dataSchema}
                onSubmit={handleCookieConsent}
              >
                <ThemeUi.Box
                  sx={{
                    backgroundColor: 'static-white',
                    width: '100%',
                    height: '100%',
                    borderRadius: 1,
                    p: 2,
                    gap: [1, 2],
                    display: 'grid',
                  }}
                >
                  <ThemeUi.Box
                    sx={{
                      'fontSize': 4,
                      'color': 'static-black',
                      'opacity': '1',
                      'justifySelf': 'end',
                      'cursor': 'pointer',
                      'mb': '-8px',
                      'transition': 'opacity 150ms ease-in-out',
                      '&:hover': {
                        opacity: '0.6',
                      },
                    }}
                    onClick={() => setSettingsVisible(false)}
                  >
                    <Icons.Close />
                  </ThemeUi.Box>
                  <FormComponents.CheckboxField
                    disabled
                    initialValue
                    scope="#/properties/baseConsent"
                    sx={{
                      color: 'gray',
                      cursor: 'not-allowed',
                      div: {
                        fontSize: '10px',
                        svg: {
                          boxShadow: 'inset 0 0 0 4px gray !important',
                        },
                      },
                    }}
                  />
                  <FormComponents.CheckboxField
                    initialValue
                    scope="#/properties/adsConsent"
                    sx={{
                      div: {
                        fontSize: '10px',
                        svg: {
                          border: '2px solid',
                          borderColor: 'success',
                        },
                      },
                    }}
                  />

                  <FormComponents.SubmitButton />
                </ThemeUi.Box>
              </Forms.Provider>
            </ThemeUi.Box>
          )}
        </Suspense.Boundary>
        <HtmlContent
          sx={{
            fontSize: 0,
          }}
        >
          {translate('cookie-banner.content')}
        </HtmlContent>
        <ThemeUi.Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: [1, 2],
            flexShrink: 0,
            flexDirection: ['column', 'row-reverse'],
          }}
        >
          <ThemeUi.Button variant="secondary" onClick={handleCookieConsent}>
            {translate('cookie-banner.accept')}
          </ThemeUi.Button>
          <ThemeUi.Box
            sx={{
              'fontSize': 0,
              'cursor': 'pointer',
              '&:hover, &:active, &:focus': {
                textDecoration: 'underline',
              },
            }}
            onClick={() => setSettingsVisible(!settingsVisible)}
          >
            {translate('cookie-banner.settings')}
          </ThemeUi.Box>
        </ThemeUi.Box>
      </ThemeUi.Box>
    )
  }

  return null
}

Herz.I18n.Loader.preload(
  ['cookie-banner.accept', 'cookie-banner.content', 'cookie-banner.settings'],
  CookieConsentBanner
)

// for @loadable/components
export default CookieConsentBanner
