import * as Herz from '@rushplay/herz'
import * as Hooks from './hooks'
import * as Icons from './icons'
import * as R from 'ramda'
import * as React from 'react'
import * as ReactRouterDom from 'react-router-dom'
import * as ReactSpring from 'react-spring'
import * as ThemeUi from 'theme-ui'

import PropTypes from 'prop-types'
import querystring from 'querystring'

export function QueryPopup(props) {
  const location = ReactRouterDom.useLocation()
  const translate = Herz.I18n.useTranslate()
  const closeQuery = Hooks.useSafeUpdateQuery(
    R.mergeDeepRight({[props.activeQueryName]: null}, props.additionalQueries)
  )

  const bannerImageUrl = Hooks.useImgproxyUrl(
    translate('banner.background-image.main'),
    {
      extension: 'jpg',
      resizingHeight: 122,
    },
    'babel-fisk'
  )

  const active = R.path(
    [props.activeQueryName],
    querystring.parse(R.drop(1, location.search))
  )

  const bgTransitions = ReactSpring.useTransition(active, null, {
    from: {
      opacity: 0,
    },
    enter: {
      opacity: 1,
    },
    leave: {
      opacity: 0,
    },
  })

  const childTransitions = ReactSpring.useTransition(active, null, {
    from: {
      transform: 'scale(0.95)',
    },
    enter: {
      transform: 'scale(1)',
      trail: 500,
    },
    leave: {
      transform: 'scale(0.95)',
    },
  })

  React.useEffect(() => {
    if (!active && props.onClose) {
      // delay cleanup functions so popup is animated out first
      const id = setTimeout(() => {
        props.onClose()
      }, 500)

      return () => {
        clearTimeout(id)
      }
    }
  }, [active, props.onClose])

  return bgTransitions.map(
    ({item, key, props: styleProps}) =>
      item && (
        <ThemeUi.Box
          as={ReactSpring.animated.div}
          key={key}
          style={styleProps}
          sx={{
            zIndex: 6,
            position: 'fixed',
            top: '0px',
            left: '0px',
            right: '0px',
            bottom: '0px',
            display: 'grid',
            placeItems: 'center',
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            p: [0, 2],
          }}
        >
          {childTransitions.map(
            ({item, key, props: styleProps}) =>
              item && (
                <ThemeUi.Box
                  as={ReactSpring.animated.div}
                  key={key}
                  style={styleProps}
                  sx={{
                    width: '100%',
                    maxWidth: '400px',
                    backgroundColor: 'nav-background',
                    px: 1,
                    pt: 2,
                    pb: 3,
                    borderRadius: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    maxHeight: '100vh',
                  }}
                >
                  <ThemeUi.Box
                    sx={{
                      display: 'flex',
                      width: '100%',
                      pb: 2,
                      pr: 1,
                    }}
                  >
                    {props.backLink && (
                      <ReactRouterDom.Link to={props.backLink}>
                        <ThemeUi.Box
                          sx={{
                            'width': '32px',
                            'height': '32px',
                            'mr': '-32px',
                            'fontSize': '28px',
                            'color': 'static-white',
                            'display': 'grid',
                            'placeItems': 'center',
                            'transition': 'transform 150ms ease-in-out',
                            '&:hover': {
                              opacity: 0.6,
                            },
                            '&:active': {
                              transform: 'scale(0.9)',
                            },
                          }}
                        >
                          <Icons.NavigateBack />
                        </ThemeUi.Box>
                      </ReactRouterDom.Link>
                    )}
                    <ThemeUi.Heading
                      as="h1"
                      sx={{
                        fontSize: '24px',
                        alignSelf: 'center',
                        textAlign: 'center',
                        width: '100%',
                        fontWeight: '800',
                        mx: '32px',
                        pointerEvents: 'none',
                      }}
                    >
                      {props.title}
                    </ThemeUi.Heading>
                    <ReactRouterDom.Link to={`?${closeQuery}`}>
                      <ThemeUi.Box
                        sx={{
                          'width': '32px',
                          'height': '32px',
                          'ml': '-32px',
                          'fontSize': '28px',
                          'color': 'static-white',
                          'display': 'grid',
                          'placeItems': 'center',
                          'transition': 'transform 150ms ease-in-out',
                          '&:hover': {
                            opacity: 0.6,
                          },
                          '&:active': {
                            transform: 'scale(0.9)',
                          },
                        }}
                      >
                        <Icons.Close />
                      </ThemeUi.Box>
                    </ReactRouterDom.Link>
                  </ThemeUi.Box>
                  <ThemeUi.Box
                    sx={{
                      'height': '100%',
                      'maxHeight': 'calc(100vh - 80px)',
                      'overflowX': 'hidden',
                      'overflowY': 'auto',
                      'WebkitOverflowScrolling': 'touch',
                      '@media (hover: hover)': {
                        '::-webkit-scrollbar': {
                          width: '3px',
                          bg: 'transparent',
                        },
                        '::-webkit-scrollbar-thumb': {
                          bg: 'transparent',
                          borderRadius: '10px',
                        },
                        '::-webkit-scrollbar-track': {
                          bg: 'transparent',
                        },
                        '&:hover': {
                          '::-webkit-scrollbar-thumb': {
                            bg: 'rgba(255, 255, 255, 0.5)',
                          },
                        },
                      },
                    }}
                  >
                    <ThemeUi.Box
                      sx={{
                        height: '122px',
                        width: '100%',
                        backgroundColor: 'footer-background',
                        backgroundImage: `url(${bannerImageUrl})`,
                        backgorundSize: 'cover',
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'center',
                        mb: 2,
                      }}
                    />
                    {props.children}
                  </ThemeUi.Box>
                </ThemeUi.Box>
              )
          )}
        </ThemeUi.Box>
      )
  )
}

Herz.I18n.Loader.preload(['banner.background-image.main'], QueryPopup)

QueryPopup.defaultProps = {
  additionalQueries: {},
}

QueryPopup.propTypes = {
  activeQueryName: PropTypes.string.isRequired,
  additionalQueries: PropTypes.object,
  backLink: PropTypes.string,
  children: PropTypes.node,
}
