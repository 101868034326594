import * as React from 'react'
import * as ThemeUI from 'theme-ui'

import PropTypes from 'prop-types'

export function HtmlContent(props) {
  return (
    <ThemeUI.Box
      dangerouslySetInnerHTML={{__html: props.children}}
      as={props.as}
      sx={{
        'color': 'inherit',
        'fontFamily': 'body',
        'fontSize': [1, 2],
        'whiteSpace': 'pre-wrap',

        'p': {
          my: '0em',
        },

        'h1, h2, h3, h4': {
          fontFamily: 'heading',
          fontWeight: 'bold',
        },

        'h1': {
          fontSize: [4, 5],
        },

        'h2': {
          fontSize: [3, 4],
        },

        'h3': {
          fontSize: [2, 3],
        },

        'h4': {
          fontSize: [1, 2],
        },

        'a': {
          'color': 'inherit',
          'fontWeight': 'bold',
          'textDecoration': 'underline',
          'transition': 'opacity 100ms ease',

          '&:hover': {
            opacity: 0.6,
          },
        },
        '.center': {
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: 'center',
        },
        '.button-link': {
          'bg': 'static-white',
          'color': 'text',
          'px': 2,
          'py': 0,
          'borderRadius': 3,
          'm': 1,
          'textDecoration': 'none',
          'transition': 'transform 150ms ease-in-out',
          '&:hover, &:focus': {
            textDecoration: 'underline',
          },
          '&:active': {
            transform: 'scale(0.975)',
          },
        },
        ...props.sx,
      }}
    />
  )
}

HtmlContent.propTypes = {
  children: PropTypes.string.isRequired,
  sx: PropTypes.object,
  as: PropTypes.string,
}
